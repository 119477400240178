/**
 * Inspiration: https://github.com/WebDevSimplified/React-Infinite-Scrolling/blob/master/src/useBookSearch.js
 */
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { useRecoilState } from 'recoil';
/* Utils */
import { getTokenFromLocalStorage } from './localStorage';
/* Global state */
import { AuthenticationContext } from '../Context/AuthenticationContext';
import { unitsState } from '../Context/globalState';

const useUnitsSearch = (searchQuery, pageNumber) => {
    
    const history = useHistory();
    const { setAuth } = useContext(AuthenticationContext);

    const [ units, setUnits ] = useRecoilState(unitsState);
    const PAGE_SIZE = 8;
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(false);
    const [ hasMore, setHasMore ] = useState(true);

    useEffect(() => {
        setUnits([]);
    }, [searchQuery])
    
    useEffect(() => {

        setLoading(true);
        setError(false);
        let cancel;

        Axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
            },
            url: `https://api.bixion.com/is/unit/all/quick-status?page=${pageNumber}&page_size=${PAGE_SIZE}&search=${searchQuery}`, //URL
            cancelToken: new Axios.CancelToken(c => cancel = c)
        }).then(response => {
            
            let unitsArray = Object.entries(response.data).map((e, index) => ( 
                {
                    ...e[1], 
                    serial_number: e[0], 
                    index: index
                } 
            )); // server data transformation - serial number

            //console.log("unitsArray", unitsArray)

            let newUnitsArray = [];
            
            if (pageNumber === 0) {
                newUnitsArray = [...new Set([...unitsArray])];
            }
            else {
                newUnitsArray = [...new Set([...units, ...unitsArray])];
            }

            setUnits(newUnitsArray); // update units by new array
            setHasMore(unitsArray.length === PAGE_SIZE); // if there are less units in response than PAGE_SIZE => there are no more data on the server
            setLoading(false);
            
        }).catch(error => {
            /* Unauthorized/forbidden => Redirect to login and clear localStorage */
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem("token");
                setAuth(false);
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
            if (Axios.isCancel(error)) return;
            setError(true);
            console.log('error', error);
        })
        
        return () => cancel(); // Closing last request

    }, [searchQuery, pageNumber])
    
    return { loading, error, units, hasMore};
}

export default useUnitsSearch;
