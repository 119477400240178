/**
 * Inspiration: https://github.com/WebDevSimplified/React-Infinite-Scrolling/blob/master/src/useBookSearch.js
 */
import { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
/* Utils */
import { getTokenFromLocalStorage } from './localStorage';
/* Global state */
import { AuthenticationContext } from '../Context/AuthenticationContext';

const useCustomersSearch = (searchQuery, pageNumber) => {
    
    const history = useHistory();
    const { setAuth } = useContext(AuthenticationContext);
    
    const PAGE_SIZE = 10;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const deleteCustomer = (id) => {
        let customersCopy = [...customers]
        let customerIndex = customersCopy.findIndex(customer => customer.id === id);
        customersCopy.splice(customerIndex, 1);
        setCustomers(customersCopy);
    }

    useEffect(() => {
        setCustomers([]);
    }, [searchQuery])
    
    useEffect(() => {

        setLoading(true);
        setError(false);
        let cancel;

        Axios({
            method: 'GET',
            headers: {
                'Content-Type': 'application/json', 
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
            },
            url: `https://api.bixion.com/is/user/resellers?page=${pageNumber}&page_size=${PAGE_SIZE}&search=${searchQuery}`, //URL
            cancelToken: new Axios.CancelToken(c => cancel = c)
        }).then(response => {

            let newCustomers = [];
            
            if (pageNumber === 0) {
                newCustomers = [...new Set([...response.data])]
            }
            else {
                newCustomers = [...new Set([...customers, ...response.data])]
            }

            setCustomers(newCustomers); // update customers by new array
            setHasMore(response.data.length === PAGE_SIZE); // if there are less customers in response than PAGE_SIZE => there are no more data on the server
            setLoading(false);

        }).catch(error => {
            /* Unaouthorized/forbidden => Redirect to login and clear localStorage */
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem("token");
                setAuth(false);
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
            if (Axios.isCancel(error)) return;
            setError(true);
            console.log('error', error);
        })
        
        return () => cancel(); // Closing last request

    }, [searchQuery, pageNumber])
    
    return { loading, error, customers, deleteCustomer, hasMore};
}

export default useCustomersSearch;
