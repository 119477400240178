import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
/* Material UI */
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
/* Components */
import CustomModal from '../../lib/CustomModal/CustomModal';
import CustomTextField from '../../lib/CustomTextField/CustomTextField';
import CustomSelectField from '../../lib/CustomSelectField/CustomSelectField';
/* Global state */
import { SnackbarContext } from '../../../Context/SnackbarContext';
import { FeedActionsContext } from '../../../Context/FeedContext';
/* Utils */
import { getTokenFromLocalStorage } from '../../../Utils/localStorage';
import BackendRequest from '../../../Utils/BackendRequest';

/*const CUSTOMERS = [
    {
        id: 291,
        username: 'ondra',
        first_name: 'Ondrej',
        last_name: 'Dacer',
    },
    {
        id: 58,
        username: 'HorusAdmin',
        first_name: 'Horus',
        last_name: 'Admin',
    },
    {
        id: 399,
        username: 'zakaznik',
        first_name: 'zakaznik',
        last_name: '',
    },
];*/

const CloudAccountModal = ({
    account = null,
    handleClose,
    open,
    propagateChangeToState
}) => {

    const [ customers, setCustomers ] = useState([]);
    const { t } = useTranslation();
    const { setSnack } = useContext(SnackbarContext);
    
    const [ state, setState ] = useState(account ? { ...account } : {
        active: false,
        password: '',
        quota: 2000,
        user_id: null,
    });

    useEffect(() => {
        if (account == null) {
            const onSuccess = (response) => {
                setCustomers(response.data);
            }
            const onError = (error) => {
                setSnack({ message: t('snackbar.message.failedToLoadCustomers'), type: "error", open: true});
                console.log(error.response);
            }

            BackendRequest("get", "/user/resellers?page=0&page_size=1000&search=", null, onSuccess, onError);
        }
    }, [])
    

    /**
     * Handle change
     * @param {*} event 
     */
    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.value})
    }

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        // To do: password validation

        if (account != null) {
            const payload = {
                active: state.active, // To do
                password: state.password,
                quota: state.quota,
            }
            axios.patch(`https://api.bixion.com/bcloud/account/${account.id}`, payload, { headers: {
                'Accept': 'application/json', 
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`,    
                'Content-Type': 'application/json',
            }})
            .then((response) => {
                propagateChangeToState({
                    ...account,
                    ...payload,
                    ...(
                        account.active !== state.active || account.password !== state.password || account.quota !== state.quota
                        ? { in_sync: false }
                        : {}
                    )
                }, false);
                setSnack({ message: t('snackbar.message.accountUpdated'), type: "info", open: true});
                handleClose();
            })
            .catch((error) => {
                setSnack({ message: t('snackbar.message.failedToUpdateTheAccount'), type: "error", open: true});
                console.log(error.response);
            });
        } else {
            const payload = {
                active: state.active,
                password: state.password,
                quota: state.quota,
                user_id: state.user_id,
            };
            const selectedCustomer = customers.find((customer) => customer.id === state.user_id);
            axios.post(`https://api.bixion.com/bcloud/account`, payload, { headers: {
                'Accept': 'application/json', 
                'Authorization': `Bearer ${getTokenFromLocalStorage()}`,    
                'Content-Type': 'application/json',
            }})
            .then((response) => {
                propagateChangeToState({
                    ...selectedCustomer, // first_name, last_name, username
                    ...payload,
                    ...response.data, // id
                }, true);
                setSnack({ message: t('snackbar.message.accountCreated'), type: "info", open: true});
                handleClose();
            })
            .catch((error) => {
                setSnack({ message: t('snackbar.message.failedToCreateNewAccount'), type: "error", open: true});
                console.log(error.response);
            });
        }
    }

    let selectField;
    if (account) {
        selectField = (
            <CustomSelectField
                name="user_id"
                value={state.user_id}
                handleChange={handleChange}
                label={t('cloud.settings.label.user').toUpperCase()}
                disabled
            >
                <MenuItem disabled value={null}>
                    Select user
                </MenuItem>
                <MenuItem value={state.user_id}>{`${account.username} (id: ${account.user_id})`}</MenuItem>
            </CustomSelectField>
        );
    } else {
        selectField = (
            <CustomSelectField
                name="user_id"
                value={state.user_id}
                handleChange={handleChange}
                label={t('cloud.settings.label.user').toUpperCase()}
            >
                <MenuItem disabled value={null}>
                    Select user
                </MenuItem>
                {customers.map((customer) => (
                    <MenuItem value={customer.id}>{`${customer.username} (id: ${customer.id})`}</MenuItem>
                ))}
            </CustomSelectField>
        );
    }

    const content = (
        <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* User */}
                {selectField}
            </Grid>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Quota */}
                <CustomTextField
                    name="quota"
                    value={state.quota}
                    handleChange={handleChange}
                    label="QUOTA"
                    type="number"
                    InputProps={{
                        startAdornment: <InputAdornment />,
                        inputProps: { min: 0 }
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Active */}
                <CustomSelectField
                    name="active"
                    value={state.active}
                    handleChange={handleChange}
                    label={t('cloud.settings.label.active').toUpperCase()}
                >
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                </CustomSelectField>
            </Grid>
            <Grid item xs={12} style={{marginTop: '8px'}}>
                {/* Password */}
                <CustomTextField
                    name="password"
                    value={state.password}
                    handleChange={handleChange}
                    label="PASSWORD"
                    type="password"
                    InputProps={{
                        startAdornment: <InputAdornment />,
                    }}
                />
            </Grid>
        </Grid>
    );
    
    return (
        <CustomModal
            buttons={[
                {
                    action: handleSubmit,
                    label: t('button.save')
                },
            ]}
            content={content}
            handleClose={handleClose}
            open={open}
            title={account
                ? `${account.first_name} ${account.last_name} (iid: ${account.id})`
                : "Add new account"}
        />
    );
}

export default CloudAccountModal;
