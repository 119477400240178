import React, { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useHistory } from 'react-router';
/* Material UI */
import Paper from "@mui/material/Paper";
/* Global state */
import { SnackbarContext } from '../Context/SnackbarContext';
/* Utils */
import BackendRequest from '../Utils/BackendRequest';
import { getTokenFromLocalStorage } from '../Utils/localStorage';


export function useBrowserForm(initialBrowserState, newBrowser) {

    const history = useHistory();
    const [browserState, setBrowserState] = useState(initialBrowserState);
    const [error, setError] = useState("");
    const { setSnack } = useContext(SnackbarContext);
    const { t } = useTranslation();

    /**
     * Handle input change
     * @param {*} event 
     */
     const handleChange = (event) => {
        if (event.target.name === "password_remove")
            setBrowserState({...browserState, [event.target.name]: event.target.checked});
        else if (event.target.name === "face_blur")
            setBrowserState({...browserState, config: {...browserState.config, face_blur: event.target.value}})
        else if (event.target.name === "custom_color")
            setBrowserState({...browserState, config: {...browserState.config, custom_color: event.target.value}})
        else if (event.target.name === "flip")
            setBrowserState({...browserState, config: {...browserState.config, flip: event.target.value}})
        else
            setBrowserState({...browserState, [event.target.name]: event.target.value});
    }

    /**
     * Handle color change
     * @param {*} color 
     */
     const handleColorChange = (color) => {
        setBrowserState({...browserState, config: {...browserState.config, custom_color: color}})
    }

    /**
     * Handle logo change
     * @param {*} color 
     */
     const handleLogoChange = (file) => {
        if (file.length !== 0)
            setBrowserState({...browserState, logo: file[0]})
    }

    /**
     * Handle logo delete
     */
    const handleLogoDelete = () => {
        setBrowserState({...browserState, logo: null})
    }
    
    /**
     * Add unit to browser units (in state)
     * @param {*} newUnit 
     */
     const addUnitToState = (newUnit) => {
        let newBrowserUnits = [...browserState.new_units];      
        newBrowserUnits.push({"unit_id": newUnit.id, "unit_name": newUnit.name, "timeshift": "00:00", "unit_serial": newUnit.serial_number, "browser_unit_name": newUnit.name});
        setBrowserState({...browserState, new_units: newBrowserUnits});
    }
    
    /**
     * Delete units from browser units (in state)
     * @param {*} unitId 
     */
     const deleteUnitFromState = (unitId) => {
        if (browserState.units.findIndex(unit => unit.unit_id === unitId) !== -1) { // Array of original units
            let newBrowserUnits = [...browserState.units];
            let removeUnitsIndex = newBrowserUnits.findIndex(unit => unit.unit_id === unitId);
            newBrowserUnits.splice(removeUnitsIndex, 1);
            setBrowserState({...browserState, units: newBrowserUnits});
        }
        else { // Array of newly added units
            let newBrowserUnits = [...browserState.new_units];
            let removeUnitsIndex = newBrowserUnits.findIndex(unit => unit.unit_id === unitId);
            newBrowserUnits.splice(removeUnitsIndex, 1);
            setBrowserState({...browserState, new_units: newBrowserUnits});
        }
        setSnack({ message: t('snackbar.message.unitRemoved'), type: "info", open: true});
    }

    /**
     * Edit browser unit (in state)
     * @param {*} unitId 
     * @param {*} event 
     */
     const editUnitInState = (unitId, event) => {
        if (browserState.units.findIndex(unit => unit.unit_id === unitId) !== -1) { // Array of original units
            let newBrowserUnits = [...browserState.units];
            let unitIndex = newBrowserUnits.findIndex(unit => unit.unit_id === unitId);
            newBrowserUnits[unitIndex][event.target.name] = event.target.value;
            setBrowserState({...browserState, units: newBrowserUnits});
        }
        else { // Array of newly added units
            let newBrowserUnits = [...browserState.new_units];
            let unitIndex = newBrowserUnits.findIndex(unit => unit.unit_id === unitId);
            newBrowserUnits[unitIndex][event.target.name] = event.target.value;
            setBrowserState({...browserState, new_units: newBrowserUnits});
        }
    }

    /**
     * Validation of browser name and URL
     * @returns 
     */
     const validate = () => {
        if (browserState.title === "") {
            setError("missingBrowserName");
            return false;
        }
        
        if (browserState.url_path === ""){
            setError("missingURLPath");
            return false;
        }

        if (browserState.password_remove !== undefined && !browserState.password_remove) {
            if (browserState.password === "" && browserState.password_confirmation !== "") {
                setError("passwordMissing");
                return false;
            }
            if (browserState.password !== "" && browserState.password_confirmation === "") {
                setError("passwordConfirmationMissing");
                return false;
            }
            if (browserState.password !== browserState.password_confirmation) {
                setError("passwordsNotSame");
                return false;
            }
        }
        
        else if((!/^(#([\da-f]{3}){1,2}|rgb\(\d{1,3}?(,\s?\d{1,3}?){2}\)|rgba\((\d{1,3}?,\s?){3}(1|0?\.\d+)\))$/.test(browserState.config.custom_color))) {
            setError("wrongColorFormat");
            return false;
        }

        for (let i = 0; i < browserState.units.length; i++){
            if((!/^([+|-]?\d\d:[0-5]\d)$/.test(browserState.units[i].timeshift))) {
                setError("wrongTimeshiftFormat");
                return false;
            }
        }
        
        setError("");
        return true;
    }

    /**
     * Delete browser unit from the server and then from the global state
     * @param {*} browserUnitId 
     */
    const deleteBrowserUnit = (browserUnitId) => {
        
        const onSuccess = (response) => {
            
        }
    
        const onError = (error) => {
            console.log('error in /browser/unit/' + browserUnitId, error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("delete", "/browser/unit/" + browserUnitId, null, onSuccess, onError);
    }

    /**
     * Update browser unit
     * @param {*} unit
     */
     const updateBrowserUnit = (unit) => {
        
        const data = {
            "name": unit.browser_unit_name,
            "timeshift": unit.timeshift,
        }
        
        const onSuccess = (response) => {
            
        }
    
        const onError = (error) => {
            console.log('error in /browser/unit/' + unit.id_browser_unit, error);
            if (error.status === 401 || error.status === 403) {
                localStorage.removeItem("token");
                history.push({ pathname: '/login', state: { showSnack: true }});
            }
        }
        
        BackendRequest("post", "/browser/unit/" + unit.id_browser_unit, data, onSuccess, onError);
    }

    /**
     * Logo upload
     */
     const uploadLogo = async () => {      
        const formData = new FormData();
        formData.append('file', browserState.logo);
        let filename = uuidv4() + browserState.logo.name.slice(Math.max(0, browserState.logo.name.lastIndexOf(".")));

        /* Not used BackendRequest because of different Content-Type */
        await axios.post("https://api.bixion.com/is/files/upload?name=" + filename +"&type=other", formData, {headers: { //URL
            'Content-Type': 'image/png', 
            'Authorization': 'Bearer ' + getTokenFromLocalStorage(),
        }})
        .then(function (response) {
            console.log("Logo uploaded.", response.data.file);
        })
        .catch(function (error) {
            console.log('error in /files/upload?...')
            return null;
        });

        return filename;
    }

    return {
        browserState,
        setBrowserState,
        error,
        setError,
        handleChange,
        handleColorChange,
        handleLogoChange,
        handleLogoDelete,
        addUnitToState,
        deleteUnitFromState,
        editUnitInState,
        validate,
        deleteBrowserUnit,
        updateBrowserUnit,
        uploadLogo
    }
}

/* Styles */
const styles = {
    paper: {
        padding: '16px',
        borderRadius: '8px',
    },
};

export const Form = ({ children, ...other }) => {

    return (
        <form noValidate autoComplete="off" {...other}>
            <Paper sx={styles.paper} elevation={3}>
                {children}
            </Paper>
        </form>
    )
}
