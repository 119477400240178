import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
/* Material UI */
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
/* Components */
import AllBrowsersTableHead from '../components/Services/Common/AllItemsTableHead';
import AllBrowsersTableRow from '../components/Services/AllBrowsers/AllBrowsersTableRow';
import SearchBox from '../components//lib/CustomSearchField/CustomSearchField';
import SummaryBottomLine from '../components/Services/Common/SummaryBottomLine';
/* Global state */
import { OrderContext } from '../Context/OrderContext';
/* Utils */
import useOrder from '../Utils/useOrder';

/* Styles */
const styles = {
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: '80px',
      maxWidth: '1000px',
      '@media (max-width: 480px)': {
        marginBottom: '100px',
      }
    },
    table: {
      minWidth: 750,
    },
    paperInfo: {
      padding: '16px',
      borderRadius: '8px',
    },
    container: {
        display: 'flex',
        alignItems: 'start',
        padding: '0 0px',
        gap: '16px',
    },
    infoIcon: {
        color: 'secondary.main'
    },
    info: {
        fontWeight: 'bold',
        color: 'secondary.main'
    }
};

const ServicesAllBrowsers = () => {
  
  const { t } = useTranslation();
  const orderedBrowserRef = useRef(null);
  const [ searchQuery, setSearchQuery ] = useState("");

  const headCells = [
    { id: 'title', numeric: false, disablePadding: true, label: t("services.browsersTable.title.browser").toUpperCase(), align: 'left' },
    { id: 'browser', numeric: false, disablePadding: false, label: t("services.browsersTable.title.browserService").toUpperCase(), align: 'center' },
  ];

  const { 
    items,
    order, 
    orderBy, 
    selected, 
    setSelected,
    isSelected, 
    getComparator, 
    stableSort, 
    handleSelectAllClick, 
    handleClick, 
    handleRequestSort } = useOrder("browsers", searchQuery)
  
  const currentOrderState = useContext(OrderContext);
    
  useEffect(() => {
    if (orderedBrowserRef.current){
      orderedBrowserRef.current.scrollIntoView({block: "center"});
    }  
  }, [])

  return (
    <Box sx={styles.root}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <Typography variant="h5" gutterBottom>
            Select browsers you would like to order.
        </Typography>
        <SearchBox 
            searchQuery={searchQuery} 
            handleInputChange={(event) => setSearchQuery(event.target.value)}
            style={{height: '32px'}}
        />
      </div>

      <br />

      <Paper sx={styles.paperInfo} elevation={3}>
          <Box sx={styles.container}>
              <InfoIcon sx={styles.infoIcon} />
              <Typography variant="subtitle2" sx={styles.info}>We are preparing the transition to a new storage. For this reason, it is only possible to order the service until March 2025. Prepayment of browsers which expire after the end of March 2025 is not possible.</Typography>
          </Box>
      </Paper>

      <br />

      {Object.entries(items).length !== 0 ?
      <Paper sx={styles.paper}>
        <TableContainer>
          <Table
            sx={styles.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            {/* Head */}
            <AllBrowsersTableHead
              browser
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />

            {/* Body */}
            <TableBody>
              {stableSort(items, getComparator(order, orderBy))
                .map((browser, index) => {
                  const isItemSelected = isSelected(browser.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <AllBrowsersTableRow 
                      handleClick={handleClick}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      browser={browser}
                      innerRef={currentOrderState.browsers.order.find(orderItem => orderItem.browser_id === browser.id) ? orderedBrowserRef : null}
                    />
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>
      </Paper> : t('browsers.overview.label.noBrowsersWereFound')}

      {selected.length > 0 && <SummaryBottomLine 
        selectedLength={selected.length} 
        setSelected={setSelected} 
        label={t('services.summary.label.numberOfSelectedBrowsers')}
        continueURL={"/services/browsers/order"}
      />}
    </Box>
  );
}

export default ServicesAllBrowsers;
